<template>
    <div class="mainsearch proposition-search-params">
        <div>
            <div class="form">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group privilage-group taxi-disabled">
                            <label>Тип користувача <span class="required-icon"> *</span></label>
                            <div class="radiolist">
                                <div class="radioitem" v-for="(item, index) in userTypeList" :key="index">
                                    <input :disabled="true" type="radio" name="userType" :id="`usertype-${index}`" :checked="userType.key == item.key">
                                    <label :for="`usertype-${index}`" @click="userType = item">{{item.value}}</label>
                                    <div class="check"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>
                                <span>Державний номер ТЗ</span>
                            </label>
                            <div class="st-field">{{ car.number || `Наприклад, АХ1111ВС` }}</div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <div class="row city-container">
                                <div class="col-lg-7 city-container-item">
                                    <label>Місце реєстрації власника ТЗ <i class="icon question" data-html="true" data-placement="bottom" data-select="true" data-toggle="tooltip" title="<div class='city-tooltip-text'>Необхідно вказувати місце реєстрації власника згідно даних тех. паспорта.</div><img src='/images/city_register.png'>"></i> <span class="required-icon">*</span></label>
                                </div>
                                <div class="col-lg-5 city-container-item">
                                    <span :class="['insurance__euro-toggle', {'disabled': euroDisabled}]">
                                        <input type="checkbox" id="euroCheckedParamsForm" v-model="euroChecked" @change="euroStatus(otherCountry)" :disabled="euroDisabled || readOnly">
                                        <label for="euroCheckedParamsForm">Іноземна реєстрація</label>
                                    </span>
                                </div>
                                <div class="col-lg-12 city-container-item">
                                    <div class="st-field">{{ dataCityName || `Інші населені пункти України` }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Тип ТЗ (категорія)</label>
                            <div class="st-field">{{ carType.name || `Інші населені пункти України` }}</div>
                        </div>
                    </div>

                    <div class="col-lg-6" v-if="carTypeOptions.length !== 0 && carTypeOptions[0].value != 'B5'">
                        <div class="form-group">
                            <label v-if="isEngines">Об'єм двигуна</label>
                            <label v-else>Тип</label>
                            <div class="st-field">{{ carTypeOption.name }}</div>
                        </div>
                    </div>

                    <div class="col-lg-6" v-if="showOtk && !showRegistrationDate()">
                        <div class="form-group">
                            <div class="row">

                                <div class="col-lg-9"><label>Дата наступного ОТК</label></div>
                                <div class="col-lg-3 d-flex justify-content-end">
                                    <small>
                                        <div class="form-check">
                                             <input :disabled="readOnly" type="checkbox" @click="disableOtkDate" :checked="withoutOtk == true" class="form-check-input" id="withoutCarOtk">
                                             <label class="form-check-label" for="withoutCarOtk">Без ОТК</label>
                                        </div>
                                    </small>
                                </div>
                                <div class="col-lg-12 city-container-item">
                                    <div class="form-group otk-group">
                                        <div class="st-field">{{ otkDate }}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Тип реєстрації:</label>
                            <div class="st-field">{{ getRegistrationTypeName(registrationType) }}</div>
                        </div>
                    </div>

                     <div class="col-lg-6" v-if="showRegistrationDate()">
                        <div class="form-group">
                            <label>{{ showNameRegistrationDate() }}</label>
                            <date-picker v-model="registrationDate" :config="optionsRegisterDate"
                                         :class="{'form-control' : true, 'is-invalid' : (isInvalid(this.registrationDate) || this.registrationDateError)}"
                                         placeholder="Введите дату"></date-picker>
                            <span v-if="!registrationDateError" class="invalid-feedback">
                                Введіть коректну дату
                            </span>
                          <span v-if="registrationDateError" class="invalid-feedback">
                                    Введіть дату, яка перевищує дату початку на 15 днів
                          </span>
                        </div>
                    </div>

                    <div class="col-lg-6" v-if="userType && userType.id == 3">
                        <div class="form-group privilage-group">
                            <label>Привілеї</label>
                            <div class="st-field">{{ privilegeList.find(item => item.id == privilegeType).value }}</div>
                        </div>
                    </div>

                    <div class="row col-lg-12 mainsearch-date-group">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label>Початок дії договору</label>
                                <div class="st-field">{{ startOrderDate }}</div>
                            </div>
                        </div>
                            <div class="col-lg-4">
                                <div class="form-group disabled">
                                    <label>Закінчення дії договору</label>
                                    <div class="st-field">{{ startOrderDate }}</div>
<!--                                    <date-picker v-model="endOrderDate" :config="optionsEndOrderDate" class="form-control date-input" placeholder="Введіть дату"></date-picker>-->
                                </div>
                            </div>

                      <div class="col-lg-4" v-if="showRegistrationDate()">
                          <div class="form-group">
                            <label>Розрахований період</label>
                              <div class="st-field">{{ periodOption.name }}</div>
                          </div>
                        </div>
                      <div class="col-lg-4" v-else>
                        <div class="form-group disabled">
                          <label>
                            <span>Розрахований період</span>
                          </label>
                          <div class="search-group">
                              <div class="st-field">{{ calculatedPeriod }}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12">
                        <div :class="['form-group', 'taxi-disabled']">
                            <label>ТЗ використовується як таксі</label>
                            <div class="radiolist">
                                <div class="radioitem">
                                    <input :disabled="readOnly" type="radio" name="taxiType" id="taxitypeEnabled" :checked="isTaxi == true">
                                    <label for="taxitypeEnabled" @click="isTaxi = true">Так</label>
                                    <div class="check"></div>
                                </div>
                                <div class="radioitem">
                                    <input :disabled="readOnly" type="radio" name="taxiType" id="taxitypeDisabled" :checked="isTaxi == false">
                                    <label for="taxitypeDisabled" @click="isTaxi = false">Ні</label>
                                    <div class="check"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="history-order col-lg-12" :style="[{'display': monthsShow}]">
                            <div class="selectors filter-row" style="padding: 0px 0 35px;">
                                    <b>ТЗ НЕ використовується протягом календарних мiсяцiв</b>
                                    <div class="filter-body">
                                        <div class="col-lg-2 large-checkbox div_circle" v-for="month in monthList" :key="month.id">
                                            <input name="month"
                                                   type="checkbox"
                                                   :id="'month_'+month.id"
                                                   :value="month.id"
                                                   v-model="months"
                                                   :disabled="monthsDisabled && !months.find(item => month.id === item)"
                                            >
                                            <label :for="'month_'+month.id">
                                                <i class="icon icon-directSettlement"></i>
                                            </label>
                                            <span class="icon-text">{{ month.id }}</span>
                                        </div>
                                    </div>
                            </div>
                    </div>


<!--                    <div v-if="!checkErrors()" class="col-lg-12 modal-insurance-hint d-flex justify-content-center">-->
<!--                        <span>Заповніть всі поля (<span class="required-icon">*</span>) щоб мати можливість скористатися пошуком</span>-->
<!--                    </div>-->
<!--                    <div class="col-lg-12 insurance-nav d-flex justify-content-center">-->
<!--                        <button class="btn-blue-w" type="submit" :disabled="!checkErrors() || readOnly">-->
<!--                            Показати результати-->
<!--                        </button>-->
<!--                    </div>-->
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import Multiselect from 'vue-multiselect'
    import citySearch from '@/components/app/forms/CitySearch.vue'
    import { findCityById } from '@/api/dictionary'
    import { insuranceMixin, getPeriodMixin, autoNumberValidate,  } from '@/mixin';

    export default {
        components: {Multiselect, citySearch},
        mixins: [insuranceMixin, getPeriodMixin, autoNumberValidate],
        props: ['dataOtkDate', 'dataPrivilegeType', 'dataPlate',
                'dataExperience', 'dataType', 'dataCarTypeOption',
                'dataCity', 'dataInsuranceRegistrationType',
                'dataPrivilegeType',
                'dataCarTypeGroup',
                'dataCarNumber',
                'dataRegistrationPlaceId',
                'dataCityInfo',
                'dataCityName',
                'dataZone',
                'dataCustomerTypeId',
                'dataStartOrder',
                'dataOtk',
                'dataTaxi',
                'dataEuro',
                'dataCustomerPrivilege',
                'experienceOptions',
                'dataMonthList',
                'dataMonthListNoShow',
                'readOnly'],

        data: function () {
            return {
                autoNumberValidatePatternGroup:['vehicle','trailer','vr','mvs','diplomatic','military'],
                periodOptionStatus: false,
                periodOption: {"id": 12, "name" : "1 рік" },
                periodOptions: [
                  {"id": 15, "name" : "15 д" },
                  {"id": 1, "name" : "1 міс" },
                  {"id": 2, "name" : "2 міс" },
                  {"id": 3, "name" : "3 міс" },
                  {"id": 4, "name" : "4 міс" },
                  {"id": 5, "name" : "5 міс" },
                  {"id": 6, "name" : "6 міс" },
                  {"id": 7, "name" : "7 міс" },
                  {"id": 8, "name" : "8 міс" },
                  {"id": 9, "name" : "9 міс" },
                  {"id": 10, "name" : "10 міс" },
                  {"id": 11, "name" : "11 міс" },
                  {"id": 12, "name" : "1 рік" },
                ],
                checkValidateAutoNumber:false,
                checkHasMixedLayoutsAutoNumber:false,
                checkPrivilegeError:false,
                checkDateError:false,
                checkOtkDateError:false,
                disableCheckCarData : false,
                changedCityFlag : false,
                changedCarTypeOptionFlag : false,
                changedCarTypeFlag : false,
                car: {
                    vin: null,
                    year: null,
                    number: null
                },
                franchise: null,
                franchiseList:null,
                numberLoading:false,
                //TODO этот момент переписать, когда поменяем форму управления
                citiesOptions: [ {"id": 1,"name": "Київ","name_full_name_ua": "м. Київ, Україна", "zone":1},
                                {"id": 9,"name": "Дніпро","name_full_name_ua": "м. Дніпро, Дніпропетровська  обл., Україна", "zone":2},
                                {"id": 11,"name": "Львів","name_full_name_ua": "м. Львів, Львівська  обл., Україна", "zone":1},
                                {"id": 13,"name": "Одеса","name_full_name_ua": "м. Одеса, Одеська  обл., Україна", "zone":2},
                                {"id": 14,"name": "Харків","name_full_name_ua": "м. Харків, Харківська  обл., Україна", "zone":2},
                                {"id": 16,"name": "Запоріжжя","name_full_name_ua": "м. Запоріжжя, Запорізька  обл., Україна", "zone":3},
                                {"id": 17,"name": "Кривий Ріг","name_full_name_ua": "м. Кривий Ріг, Дніпропетровська  обл., Україна", "zone":3},
                                {"id": 21,"name": "Вінниця","name_full_name_ua": "м. Вінниця, Вінницька  обл., Україна", "zone":4},
                                {"id": 36,"name": "Маріуполь","name_full_name_ua": "м. Маріуполь, Донецька  обл., Україна", "zone":4},
                                {"id": 41,"name": "Полтава","name_full_name_ua": "м. Полтава, Полтавська  обл., Україна", "zone":4}],
                city: null,
                experience: {"name":"Менше 3 років","value":false},
                isTaxi: false,
                taxiDisabled: false,
                euroChecked: false,
                euroDisabled: false,
                carTypeOption: {"name":"до 1600 см3","value":"B1","auto_kind_limit":1600,"engineVolume":1600},
                carType: {"name":"Легкові автомобілі", "external_id":"B1", "group":"CAR","engine":"до 1600 см3",
                          "engines":[{"name":"до 1600 см3","value":"B1","auto_kind_limit":1600,"engineVolume":1600},
                                     {"name":"1601-2000 см3","value":"B2","auto_kind_limit":2000,"engineVolume":1800},
                                     {"name":"2001-3000 см3","value":"B3","auto_kind_limit":3000,"engineVolume":2500},
                                     {"name":"более 3001 см3","value":"B4","auto_kind_limit":3500,"engineVolume":3500}],
                            "types":[]},
                carTypes: [
                            {"name":"Легкові автомобілі","group":"CAR", "external_id":"B1",
                                   "engines":[{"name":"до 1600 см3","value":"B1"},
                                        {"name":"1601-2000 см3","value":"B2"},
                                        {"name":"2001-3000 см3","value":"B3"},
                                        {"name":"более 3001 см3","value":"B4"}], "types":[]},
                            {"name":"Автобус","group":"BUS", "engines":[],
                                    "types":[{"name":"менше 20 осіб","value":"D1"},
                                             {"name":"більше 20 осіб","value":"D2"}]},
                            {"name":"Вантажні автомобілі","group":"CARGO", "engines":[],
                                    "types":[{"name":"менше 2-х тонн","value":"C1"},
                                            {"name":"більше 2-х тонн","value":"C2"}]},
                            {"name":"Причіп, Трейлер","group":"TRAILER","engine":null, "engines":[],
                                    "types":[{"name":"До вантажного авто","value":"E"},
                                            {"name":"До легкового авто","value":"F"}]},
                            {"name":"Мотоцикли та моторолери","group":"BIKE", "external_id":"A1",
                                    "engines":[{"name":"300 см3 або менше","value":"A1"},
                                            {"name":"більше 300 см3","value":"A2"}], "types":[]},
                            {"name":"Легковий електромобіль","group":"CAR","external_id":"B5","engine":null,"engines":[],"types":[]}
                ],
                plateInfoLoading: true,
                isLoading: false,
                from: 'plate',
                otkDate: null,
                withoutOtk: false,
                startOrderDate: moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()+1)).format('DD.MM.YYYY'),
                endOrderDate: new Date((new Date().getFullYear()+1), new Date().getMonth(), new Date().getDate()),
                userTypeList: [],
                userType: {},
                registrationDate: null,
                registrationDateError: false,
                //calculatedPeriod: '1 рік',
                optionsRegisterDate: {
                    format: 'DD.MM.YYYY',
                    useCurrent: false,
                    minDate: new Date(new Date().getFullYear(), new Date().getMonth(), (new Date().getDate() + 2)),
                    locale: 'uk',
                    icons: {
                        previous: 'icon arrow-point-to-left',
                        next: 'icon arrow-point-to-right',
                    },
                },
                optionsOtkDate: {
                    format: 'DD.MM.YYYY',
                    useCurrent: false,
                    minDate: new Date(new Date().getFullYear(), new Date().getMonth(), (new Date().getDate() + 1)),
                    locale: 'uk',
                    icons: {
                        previous: 'icon arrow-point-to-left',
                        next: 'icon arrow-point-to-right',
                    },
                },
                optionsStartOrderDate: {
                    format: 'DD.MM.YYYY',
                    useCurrent: false,
                    minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                    locale: 'uk',
                    icons: {
                        previous: 'icon arrow-point-to-left',
                        next: 'icon arrow-point-to-right',
                    },
                },
                optionsEndOrderDate: {
                    format: 'DD.MM.YYYY',
                    useCurrent: false,
                    minDate: new Date(new Date().getFullYear(), new Date().getMonth(), (new Date().getDate() + 1)),
                    locale: 'uk',
                    icons: {
                        previous: 'icon arrow-point-to-left',
                        next: 'icon arrow-point-to-right',
                    },
                },
                privilegeList: [],
                insuranceRegistrationType: [],
                registrationType: 1,
                privilegeType: null,
                isLegalType: false,
                privilegeDisabled: false,
                carNumberInfo: {
                    status: false,
                    mark: null,
                    model: null,
                    vin: null,
                    year: null,
                    autoCategory: null,
                    city: {
                        id: null,
                        name_ua: null,
                        name_full_name_ua: null
                    }
                },
                monthList: [{'id': 1},{'id': 2},{'id': 3},{'id': 4},{'id': 5},{'id': 6},{'id': 7},{'id': 8},{'id': 9},{'id': 10},{'id': 11},{'id': 12}],
                months: [],
                monthsDisabled: false,
                monthsShow: '',
            };
        },

        created() {
            this.disableCheckCarData = true;
            this.withoutOtk = false;
            this.privilegeList = this.$store.getters.CUSTOMER_PRIVILEGE;
            this.userTypeList = this.$store.getters.CUSTOMER_TYPES;
            this.insuranceRegistrationType = this.$store.getters.INSURANCE_REGISTRATION_TYPE;
            this.registrationType = this.getPermanentRegistration().id;
            this.setCustomerType();
            this.setNumberCar();
            this.setCityInfo();
            this.setCarTypeGroup();
            this.setCarType();
            this.setInsuranceRegistrationType();
            this.setStartData();
            this.setOtkData();
            this.setTaxi();
            this.setEuro();
            this.setMonthList();
            this.setMonthListShow();

            //this.franchiseList = this.$store.getters.FRANCHISE_LIST;
        },

        mounted: function () {

            this.userType = this.userTypeList.find(option => option.id === 1);
            setTimeout(() => {
                $('[data-toggle="tooltip"]').tooltip({
                    html: true
                });
              this.disableCheckCarData = false;
            }, 1000);
        },

        computed: {
            getOtkMinDate(){
              return new Date(new Date().getFullYear(), new Date().getMonth(), (new Date().getDate() + 1));
            },
            disabled: function () {
                if(this.errorPlate
                    || this.city == null) {
                    return true;
                }

                return false;
            },

            errorPlate: function () {
                if(this.plate != null
                    && this.plate != ""
                    && ! this.validatePlate(this.plate)) {
                    return true;
                }

                return false;
            },

            carTypeOptions: function () {
                if(this.carType === null || this.carType === undefined) {
                    return [];
                }

                if(this.carType.types === undefined && this.carType.engines === undefined) {
                    return [];
                }

                if(this.carType.types.length === 0) {
                    return this.carType.engines;
                }

                if(this.carType.engines.length == 0) {
                    return this.carType.types;
                }
            },

            isEngines: function () {
                return this.carType.engines.length !== 0;
            },

            showOtk: function() {
              let startDate = moment(this.startOrderDate, 'DD/MM/YYYY');
              let startYear = startDate.year();
              let startMonth = startDate.month();
              let startDay = startDate.date();
/*
                setTimeout(() => {
                    $(".otk-group input.form-control").mask("99.99.9999");
                    let data_value = null;
                    $(".otk-group input.form-control").keyup(function(e){
                        data_value = $(".otk-group input.form-control").val().replace(/\_/g, '');
                    });
                    $(".otk-group input.form-control").focusout(function() {
                        $(".otk-group input.form-control").val(data_value);
                    })
                }, 200);
*/

                if(this.carType === null || this.carType === undefined) {
                    this.otkDate = null;
                    return false;
                }
                if (this.carType.group === "BUS" ) {
                    let maxDate = new Date(startYear, (startMonth + 6), startDay);
                    let otkDate = moment(this.otkDate, 'DD.MM.YYYY').toDate();
                    if (otkDate > maxDate) {
                      this.otkDate = moment(maxDate).format('DD.MM.YYYY');
                    }
                    this.$set(this.optionsOtkDate, 'maxDate', maxDate);
                    return true;
                } else if((this.carType.group === "CARGO" || this.carType.group === "BUS" || this.carType.group === "TRAILER")
                    || this.isTaxi){
                    this.$set(this.optionsOtkDate, 'maxDate', new Date(startYear, (startMonth + 24), startDay))
                    return true;
                }else{
                  this.otkDate = null;
                  return false;
                }
            },
            cityStore() {
                if (this.$store.state.osagoStore.requestData === null || this.$store.state.osagoStore.requestData === undefined) {
                    return false;
                } else {
                    if (this.$store.state.osagoStore.requestData.cityInfo !== null && this.$store.state.osagoStore.requestData.cityInfo !== undefined) {
                        if(this.$store.state.osagoStore.requestData.cityInfo.id !== 0){
                            return this.$store.state.osagoStore.requestData.cityInfo;
                        }else{
                            return false;
                        }
                    }
                }
            },
          calculatedPeriod(){
              return this.periodOption.name;
          }
        },

        watch: {
            registrationType: function (newValue) {
                this.checkValidateAutoNumber = false;
                switch (newValue) {
                    case this.getTemporaryRegistration().id:
                        this.monthsDisabled = true;
                        this.months = [];
                        this.euroChecked = false;
                        this.euroDisabled = true;
                        this.isTaxi = false;
                        this.taxiDisabled = true;
                        //this.calculatedPeriod = 1 + ' рік';
                        this.periodOption = this.periodOptions.find(option => option.id === 12);
                        this.checkPeriodOption();
                        this.autoNumberValidatePatternGroup = ['temporary'];
                        return true;
                    /*case this.getTemporaryEntry().id:
                        this.monthsDisabled = true;
                        this.months = [];
                        this.euroChecked = true;
                        this.euroDisabled = true;
                        this.isTaxi = false;
                        this.taxiDisabled = true;
                        this.autoNumberValidatePatternGroup = ['vehicle','trailer','vr','mvs','diplomatic','military'];
                        return true;*/
                    case this.getPermanentRegistration().id:
                        this.setMonthsDisabled();
                        this.euroChecked = false;
                        this.euroDisabled = false;
                        this.isTaxi = false;
                        this.taxiDisabled = false;
                        this.registrationDate = null;
                        //this.calculatedPeriod = 1 + ' рік';
                        this.periodOption = this.periodOptions.find(option => option.id === 12);
                        this.checkPeriodOption();
                        this.autoNumberValidatePatternGroup = ['vehicle','trailer','vr','mvs','diplomatic','military'];
                        return true;
                    default:
                        this.autoNumberValidatePatternGroup = ['vehicle','trailer','vr','mvs','diplomatic','military'];
                        return true;
                }
                this.registrationDateError = false;
                this.registrationDate = null;
            },
            carType: function (newValue,oldVal) {
                if(!this.disableCheckCarData && !!this.car.number && newValue && oldVal && newValue.group != oldVal.group ){
                  this.changedCarTypeFlag = true;
                  this.$alert("Ви намагаєтесь змінити параметри, які ми отримали з бази МТСБУ. Деякі СК забороняють укладання договорів з даними, які відрізняються від офіційних реєстрів", '', 'error', {width: '300px'});
                }
                this.carTypeOption = this.carTypeOptions[0];

                if(this.carType == undefined){
                        return;
                    }

                if (this.carType.group === 'BIKE' || this.carType.group === 'CAR') {
                    this.userTypeList = this.$store.getters.CUSTOMER_TYPES;
                } else {
                    if(this.userType.id === 3){
                        this.userType = this.userTypeList.find(option => option.id === 1);
                    }
                }
                if(this.registrationType !== 3){
                    if(this.carType.group === "CARGO" || this.carType.group === "TRAILER" || this.carType.group === "BUS"){
                        this.euroDisabled = true;
                        this.taxiDisabled = true;
                        this.isLegalType = true;
                    }else{
                        this.euroDisabled = false;
                        this.taxiDisabled = false;
                        if(this.taxi !== true){
                            this.isLegalType = false;
                        }
                    }
                    if(this.carType.group === "BIKE" || this.carType.group === "CARGO" || this.carType.group === "TRAILER" || this.carType.group === "BUS"){
                        this.taxiDisabled = true;
                        this.isTaxi = false;
                    }else{
                        this.taxiDisabled = false;
                    }
                    if(this.carType.group === "CARGO" || this.carType.group === "TRAILER" || this.carType.external_id === "B5" || this.carType.group === "BUS"){
                        this.isLegalType = true;
                        this.privilegeChecked = false;
                    }else{
                        if(this.isTaxi !== true){
                            this.isLegalType = false;
                        }
                    }

                    if (this.carType.group === "BIKE" || this.carType.group === 'CAR') {
                        if (this.userType.id === 3) {
                            this.euroDisabled = true;
                        }
                        if (this.euroChecked) {
                            if(this.userType.id === 3){
                                this.userType = this.userTypeList.find(option => option.id === 1);
                            }
                            this.userTypeList = this.$store.getters.CUSTOMER_TYPES.filter((type)=>{
                                return type.id !== 3;
                            });
                        }
                    }
                }

                if (newValue.external_id === "B5") {
                    this.carTypeOption = {
                        value: "B5"
                    };
                }
            },
            carTypeOption: function (newValue,oldVal) {
              if(!this.disableCheckCarData && !!this.car.number && newValue && oldVal && newValue.value != oldVal.value ){
                this.changedCarTypeOptionFlag = true;
                this.$alert("Ви намагаєтесь змінити параметри, які ми отримали з бази МТСБУ. Деякі СК забороняють укладання договорів з даними, які відрізняються від офіційних реєстрів", '', 'error', {width: '300px'});
              }
            },

            'car.number': function(newVal,oldVal){
                this.checkValidateAutoNumber = false;
                this.car.number = newVal.replace(/\s/g, '').replace(/,/g, '');
                if(this.car.number.length === 8){
                    this.getCarInfo();
                }else{
                    this.carNumberInfo.status = false;
                }
            },

            isLegalType: function(newValue) {
                if(this.isLegalType == true){
                    this.privilegeType = null;
                }
            },
            isTaxi: function (newValue) {
                if(this.isTaxi === true){
                    this.privilegeType = null;
                    if(this.userType.id === 3){
                        this.userType = this.userTypeList.find(option => option.id === 1);
                    }
                }else{
                    if(this.carType.external_id !== "B5" && this.euroChecked === false){
                        this.userTypeList = this.$store.getters.CUSTOMER_TYPES;
                    }
                }
            },
            userType: function(newValue) {
                this.disableCheckCarData = true;
                this.changeCarTypes();
                if(newValue.id === 3){
                    this.registrationType = 1;

                    this.euroChecked = false;
                    if(this.carType.group !== 'CAR' && this.carType.group !== 'BIKE' && this.carNumberInfo.autoCategory !== 'B4') {
                        this.carType = this.carTypes[0];
                    }
                    //this.registrationType = this.insuranceRegistrationType[0].id;

                  if (this.carNumberInfo.autoCategory == 'B4' && !!this.car.number){
                    this.$alert("Пільгове страхування авто з об'ємом понад 2500см3 заборонено. Бажаєте розрахувати без пільги?", '', 'error', {width: '300px'});
                    setTimeout(() => {
                      this.selectUserType(1);
                      this.carTypeOption = {"name":"более 3001 см3","value":"B4"};
                    }, 500);
                  }
                }
                if(this.registrationType !== 3){
                    if (this.carType.group === 'CAR') {
                        if(this.userType.id != 3){
                            this.privilegeType = null;
                            this.carTypeOptions[2].name = '2001-3000 см3';
                            this.carTypeOptions[2].auto_kind_limit = 3000;
                            this.euroDisabled = false;
                            this.taxiDisabled = false;
                        }else{
                            this.carTypeOption = this.carTypeOptions[0];
                            this.carTypeOptions[2].name = '2001-2500 см3';
                            this.carTypeOptions[2].auto_kind_limit = 2500;
                            this.euroDisabled = true;
                            this.isTaxi = false;
                            this.taxiDisabled = true;
                        }
                    }
                    if (this.carType.group === 'BIKE') {
                        if(this.userType.id != 3){
                            this.privilegeType = null;
                            this.euroDisabled = false;
                        }else{
                            this.euroDisabled = true;
                        }
                    }
                }
                this.checkErrors();

               // this.disableCheckCarData = false;
            },

            euroChecked: function(newValue) {
                this.changeCarTypes()
            },
            startOrderDate: function(newValue) {
                if(this.registrationType == this.getTemporaryRegistration().id){
                  this.checkPeriodOption();
                  this.getContractPeriod();
                }else{
                  this.getContractPeriod();
                }
            },
            otkDate: function(newValue) {
                this.checkOtkDateError = false;
                this.getContractPeriod()
            },
            /*registrationDate: function(newValue) {
                this.getContractPeriod()
            },*/
            months: function () {
                this.monthsDisabled = this.months.length >= 6;
            },
        },

        methods: {
            checkPeriodOption(){
              if (!this.periodOption || !this.periodOption.id) {
                this.periodOptionStatus = false;
                return;
              }
              this.periodOptionStatus = true;
              const endDate = moment(this.startOrderDate, 'DD.MM.YYYY').add(this.periodOption.id, (this.periodOption.id == 15 ? 'days' : 'months'));

              if(this.registrationType == this.getTemporaryRegistration().id){
                this.registrationDate = endDate.format('DD.MM.YYYY');
              }

              endDate.subtract(1, 'days');

              this.endOrderDate = endDate.format('DD.MM.YYYY');
            },
            setMonthsDisabled() {
                //console.log('setMonthsDisabled', this.registrationType, this.withoutOtk, (this.carType.group !== "CARGO" && this.carType.group !== "TRAILER" && this.carType.group !== "BUS"));
                if(this.registrationType != 1)
                {
                    this.monthsDisabled = true;
                    return;
                }
                if(this.withoutOtk || (this.carType.group !== "CARGO" && this.carType.group !== "TRAILER" && this.carType.group !== "BUS"))
                {
                    this.monthsDisabled = false;
                    return;
                }

                this.months = [];
                //console.log('this.months', this.months);
                this.monthsDisabled = true;
            },
            setInsuranceRegistrationType() {
                if (this.dataInsuranceRegistrationType) {
                    this.registrationType = this.dataInsuranceRegistrationType;
                }
            },
            getRegistrationTypeName(registrationType) {
                return this.insuranceRegistrationType.find(item => item.id == registrationType).value;
            },
            disableOtkDate(event) {
                this.withoutOtk = event.target.checked ? true : false;
                this.setMonthsDisabled();
                this.otkDate = null;
            },
            setCustomerType() {
                setTimeout(() => {
                    if (this.dataCustomerTypeId) {
                        this.userType = this.userTypeList.find(option => option.id == this.dataCustomerTypeId);
                    }
                    if(this.dataCustomerPrivilege){
                        this.privilegeType = this.dataCustomerPrivilege;
                    }
                }, 1000);
            },
            setNumberCar(){
                if (this.dataCarNumber) {
                    this.car.number = this.dataCarNumber;
                }
            },
            setCityInfo(){
                if (this.dataRegistrationPlaceId) {
                    findCityById(this.dataRegistrationPlaceId).then((response) => {
                        this.city = response.data?.data;
                    });
                }
            },
            setCarTypeGroup() {
                if (this.dataCarTypeGroup) {
                    this.carType = this.carTypes.find(option => option.group == this.dataCarTypeGroup)
                }
            },
            setCarType() {
                if (this.dataCarType) {
                    this.carType = this.carTypes.find(option => option.group == this.dataCarTypeGroup)
                }
                if(this.dataCarTypeOption){
                    setTimeout(() => {
                        this.carTypeOption = this.carTypeOptions.find((item) => {
                            return this.dataCarTypeOption == item.value;
                        });
                    }, 500);
                }
            },
            setStartData() {
               if (this.dataStartOrder) {
                   this.startOrderDate = this.dataStartOrder;
               }
            },
            setOtkData() {
               if (this.dataOtk) {
                   setTimeout(() => {
                        this.otkDate = moment.unix(this.dataOtk).format("DD.MM.YYYY");
                   }, 2000);
               }
            },
            setTaxi() {
               if (this.dataTaxi) {
                   if(this.dataTaxi === 1){
                       this.isTaxi = true;
                   }else{
                       this.isTaxi = false;
                   }
               }
            },
            setMonthList() {
                if (this.dataMonthList) {
                    this.months = this.dataMonthList;
                }
            },
            setMonthListShow() {
                if (this.dataMonthListNoShow) {
                    this.monthsShow = 'none';
                }
            },
            setEuro() {
                setTimeout(() => {
                    if (this.dataEuro) {
                        if(this.dataEuro === 1){
                            this.euroChecked = true;
                        }else{
                            this.euroChecked = false;
                        }
                    }
                }, 1000);
            },


            changeCarTypes() {
                let carTypes = [{"name":"Легкові автомобілі","group":"CAR","external_id":"B1",
                                    "types":[{"name":"до 1600 см3","value":"B1"},{"name":"1601-2000 см3","value":"B2"},{"name":"2001-3000 см3","value":"B3"},
                                      {"name":"более 3001 см3","value":"B4"}],"engines":[]},
                                        {"name":"Автобус","group":"BUS","engines":[],"types":[{"name":"менше 20 осіб","value":"D1"},
                                        {"name":"більше 20 осіб","value":"D2"}]},{"name":"Вантажні автомобілі","group":"CARGO","engines":[],
                                        "types":[{"name":"менше 2-х тонн","value":"C1"},{"name":"більше 2-х тонн","value":"C2"}]},
                                      {"name":"Причіп, Трейлер","group":"TRAILER","engine":null,"engines":[],"types":[{"name":"До вантажного авто","value":"E"},{"name":"До легкового авто","value":"F"}]},{"name":"Мотоцикли та моторолери"
                                        ,"group":"BIKE","external_id":"A1","types":[{"name":"300 см3 або менше","value":"A1"},{"name":"більше 300 см3","value":"A2"}],"engines":[]},
                                        {"name":"Легковий електромобіль","group":"CAR","external_id":"B5","engine":null,"engines":[],"types":[{"name":"","value":"B5"}]}];
                if(this.userType.id === 3){
                    let availableEngines = ['A1', 'A2', 'B1', 'B2', 'B3'];
                    if(this.euroChecked){
                        availableEngines = ['A2', 'B1', 'B2', 'B3'];
                        if(this.carType.group == 'BIKE') this.carType = carTypes[0];
                    }
                    carTypes = carTypes.filter((carType)=>{
                        return availableEngines.includes(carType.external_id);
                    });
                }

                this.carTypes = carTypes;
            },
            showNameRegistrationDate() {
                switch (this.registrationType) {
                    case this.getTemporaryRegistration().id:
                        return 'Дата закінчення реєстрації';
                    /*case this.getTemporaryEntry().id:
                        return 'Дата виїзду';*/
                    default:
                        return '';
                }
            },
            showRegistrationDate() {
                 if(this.registrationType === this.getTemporaryRegistration().id /*|| this.registrationType === this.getTemporaryEntry().id*/){
                     this.otkDate = null;
                     return true;
                 }else{
                     return false;
                 }
            },
            shouldDisableRegistrationType(option){
              return option.id === 3 && this.userType.id === 3;
            },
            checkPrivilege() {
                if (this.userType?.id === 3 && this.privilegeType === null) {
                    return false
                }

                return  true;
            },
            checkDateOrder() {
                let startYear = parseInt(moment(this.startOrderDate,'DD/MM/YYYY').format('YYYY'));
                let startMonth = parseInt(moment(this.startOrderDate,'DD/MM/YYYY').format('MM'));
                let startDay = parseInt(moment(this.startOrderDate,'DD/MM/YYYY').format('DD'));
                let endYear = parseInt(moment(this.endOrderDate,'DD/MM/YYYY').format('YYYY'));
                let endMonth = parseInt(moment(this.endOrderDate,'DD/MM/YYYY').format('MM'));
                let endDay = parseInt(moment(this.endOrderDate,'DD/MM/YYYY').format('DD'));

                let diffDays = moment(new Date((endYear), new Date(endMonth), new Date(endDay))).diff(new Date((startYear), new Date(startMonth), new Date(startDay)), 'days', true);

                if (diffDays < 1) {
                    return false
                }

                return  true;
            },
            getNumberMask() {
                if (this.euroChecked) {
                    return 'NNNNNNNNN';
                } else {
                    return 'MMMMMMMMM';
                }
            },
            checkResultOsagoRoute() {
                return this.$router.currentRoute.name === 'osagoResults';
            },
            checkRegistrationDateError() {
                return this.registrationType !== this.getPermanentRegistration().id &&
                    this.registrationDate === null;
            },
            findCarTypes(carType) {
                 let carTypeOption = null;

                 this.carTypes.forEach((value, index) => {
                    return value.types.forEach((item, key) => {
                        if (item.value === carType) {
                            this.carType = this.carTypes[index];
                            carTypeOption = item;

                            return false;
                        }
                    });
                });

                 return carTypeOption;
            },
            otherCity() {
                let name = 'Другие населенные пункты Украины';

                let results = this.cities.filter((city) => {
                    return name.includes(this.cityPartner.name_full_name_ua);
                });

                if(results.length === 0) {
                    return {
                        name_full_name_ua: name,
                    };
                }

                return results[0];
            },

            otherCountry: function () {
                let name = 'ТС зареєстровано в іншій країні';

                let results = this.otherVariants.filter((city) => {
                    return name.includes(city.name_ru);
                });

                if(results.length === 0) {
                    return {
                        name: null,
                    };
                }

                return results[0];
            },

            fetchCities(query) {
                axios.get('/api/directories/cities/find?city=' + query).then(({data}) => {
                    this.cities = data.data;
                    this.citiesOptions = data.data;

                    if(this.citiesOptions.length === 0) {
                        this.citiesOptions.push(this.otherCity);
                    }
                }).catch(err => (console.log(err)));
            },
            searchCities(query) {
                if(query.length > 1){
                    this.fetchCities(query);
                }
            },
            selectUserType(key) {
              this.userType = { ...this.userTypeList.find(item => item.id === key) };
            },
            checkErrors() {
                if (this.carType === undefined || this.carType == null) {
                    return false;
                }
                if(this.numberLoading){
                  return false;
                }
                if(this.city == null || this.city.id == null){
                  return false;
                }

                if (this.otkDate == null && !this.withoutOtk) {

                    if (this.otkDate == null && !this.showRegistrationDate() && (this.carType.group == 'CARGO' || this.carType.group == 'BUS' || (this.carType.group == 'TRAILER' && this.carTypeOption.value !== 'F'))) {
                        return false;
                    }

                    if (this.otkDate === null && this.isTaxi === true) {
                        return false;
                    }
                }

                if (this.carType.group == 'TRAILER') {
                    return ((this.carTypeOption !== null || this.carTypeOption === undefined)
                    && this.carType != null
                    && this.city != null);
                }


                return ((this.carTypeOption !== null || this.carTypeOption === undefined)
                    && this.carType != null
                    && this.city != null);
            },

            citySelect(data) {
                if(!this.disableCheckCarData && !!this.car.number && this?.city?.zone != data.zone ){
                  this.changedCityFlag = true;
                  this.$alert("Ви намагаєтесь змінити параметри, які ми отримали з бази МТСБУ. Деякі СК забороняють укладання договорів з даними, які відрізняються від офіційних реєстрів", '', 'error', {width: '300px'});
                }
                this.city = data.city;
            },
            executeSendData(){
              this.submitForm = true;
              this.checkPrivilegeError = false;
              this.checkDateError = false;
              this.registrationDateError = false;

              if (! this.checkPrivilege()) {
                this.checkPrivilegeError = true;

                return false;
              }
              if (! this.checkDateOrder()) {
                this.checkDateError = true;

                return false;
              }

              if (this.car.number && this.hasMixedLayouts(this.car.number)) {
                this.checkHasMixedLayoutsAutoNumber = true;
                return false;
              }else{
                this.checkHasMixedLayoutsAutoNumber = false;
              }

              if (this.car.number && ! this.validateCarNumber(this.car.number,this.autoNumberValidatePatternGroup)) {
                this.checkValidateAutoNumber = true;
                return false;
              }else{
                this.checkValidateAutoNumber = false;
              }

              if (this.checkRegistrationDateError()) {
                this.registrationDateError = true;
                return false;
              }else{
                this.registrationDateError = false;
              }
              //

              if (this.checkErrors()){
                this.isLoading = true;

                if (this.withoutOtk) {
                  this.otkDate = null;
                  //this.otkDate = moment().add('Y').utc(true).unix();
                } else {
                  if (this.otkDate) {
                    this.otkDate = moment(this.otkDate, "DD.MM.YYYY").utc(true).unix();
                  }
                }

                if (this.registrationDate) {
                  this.registrationDate = moment(this.registrationDate, "DD.MM.YYYY").utc(true).unix();
                }

                let params = {
                  changedCarTypeOptionFlag : this.changedCarTypeOptionFlag,
                  changedCarTypeFlag : this.changedCarTypeFlag,
                  changedCityFlag : this.changedCityFlag,
                  months: this.months,
                  isTaxi: this.isTaxi ? 1 : 0,
                  isEuroCar: this.euroChecked ? 1 : 0,
                  experience: this.experience,
                  franchise: 'all',
                  autoCategoryType: this.carTypeOption.value,
                  registrationPlaceId: this.city.id,
                  zone: this.city.zone,
                  cityName: this.city.name_full_name_ua,
                  otkDate: this.otkDate ? moment(this.endOrderDate, "DD.MM.YYYY").add(1, 'days').utc(true).unix() : null,
                  withoutOtk: this.withoutOtk ? 1 : 0,
                  startDate: this.startOrderDate,
                  carTypeInfo: this.carType,
                  cityInfo: this.city,
                  customerPrivilege: this.privilegeType ?? 1,
                  registrationType: this.registrationType,
                  customerType: this.userType?.id,
                  endDate: this.registrationDate ? moment(this.endOrderDate, "DD.MM.YYYY").add(1, 'days').utc(true).unix() : null,
                  period_id: this.periodOption?.id || 12,
                  car: {
                    vin: this.car.vin,
                    year: this.car.year,
                    brand: '',
                    model: '',
                    number: this.car.number
                  }
                };

                let paramsCar = {
                  vin: this.car.vin,
                  year: this.car.year,
                  brand: '',
                  model: '',
                  number: this.car.number
                };

                let dateInfo = {
                  startDate: this.startOrderDate,
                  endDate: moment(this.endOrderDate, "DD.MM.YYYY").format('DD.MM.YYYY'),
                  period: this.calculatedPeriod,
                  period_id: this.periodOption?.id || 12
                }

                //TODO быстрый фикс, это всё нужно будут сделать номрально, чере vue
                $('#modalInsurance').modal('hide');
                this.$store.commit('SET_SEARCH_PARAMS', params);
                this.$store.commit('SET_OSAGO_OFFERS', null);
                this.$store.commit('SET_CAR_INFO', paramsCar);
                //console.log('SET_DATE_INFO', dateInfo)
                this.$store.commit('SET_DATE_INFO', dateInfo);

                if (this.checkResultOsagoRoute()) {
                  this.$router.replace({ query: params });
                  this.$router.go();
                } else {
                  this.$router.push({name: `osagoResults`, query: params});
                }
              }
            },
            send() {
              if (!!this.car.number) {
                this.setCarInfo(false).then(() => {
                  this.executeSendData();
                });
              } else {
                this.executeSendData();
              }
            },

            isInvalid(val) {
                return val == null && this.submitForm;
            },

            isInvalidOtkDate(val) {
                return val == null && !this.withoutOtk && this.submitForm;
            },
            euroStatus(value){
                $('[data-toggle="tooltip"]').tooltip('hide');
                if(this.euroChecked === true){
                    this.city = value;
                    $('.params-city-status').addClass('disabled');
                }else{
                    this.city = null;
                    $('.params-city-status').removeClass('disabled');
                }
            },

            choseCity() {
                this.euroChecked = false;
                $('.params-city-status').removeClass('disabled');
            },

            checkCarType(){
                this.setMonthsDisabled();
                if(this.registrationType !== 3){
                    if(this.carType !== undefined){
                        if(this.carType.group === "CARGO" || this.carType.group === "TRAILER"){
                            this.euroDisabled = true;
                            this.euroChecked = false;
                            $('.params-city-status').removeClass('disabled');
                        }else{
                            this.euroDisabled = false;
                        }
                    }
                }
                this.withoutOtk = false;
            },
           /* getPeriodOld(){
              let currentValue = this.registrationDate ? this.registrationDate : this.otkDate;

              let currentYear = parseInt(moment(currentValue,'DD/MM/YYYY').format('YYYY'));
              let currentMonth = parseInt(moment(currentValue,'DD/MM/YYYY').format('MM'));
              let currentDay = parseInt(moment(currentValue,'DD/MM/YYYY').format('DD'));
              let startYear = parseInt(moment(this.startOrderDate,'DD/MM/YYYY').format('YYYY'));
              let startMonth = parseInt(moment(this.startOrderDate,'DD/MM/YYYY').format('MM'));
              let startDay = parseInt(moment(this.startOrderDate,'DD/MM/YYYY').format('DD'));
              this.endOrderDate = new Date((currentYear), new Date(currentMonth-1), new Date(currentDay));

              let diffYears = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'years', true);
              let diffMonths = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'months', true);
              let diffDays = moment(new Date((currentYear+1), new Date(currentMonth-1), new Date(currentDay-1))).diff(new Date((startYear+1), new Date(startMonth-1), new Date(startDay-1)), 'days', true);

              if(diffYears >= 1){
                let count = Math.round(diffYears);
                if(count >= 2){
                  this.calculatedPeriod = count+' роки'
                }else if(count >= 5){
                  this.calculatedPeriod = count+' років'
                }else{
                  this.calculatedPeriod = count+' рік'
                }
              }else if(diffDays > 15){
                let count = Math.ceil(diffMonths);
                if(count < 5 && count > 1){
                  this.calculatedPeriod = count+' місяці'
                }else if(count >= 5){
                  this.calculatedPeriod = count+' місяців'
                }else{
                  this.calculatedPeriod = count+' місяць'
                }
              }else{
                let count = diffDays;
                if(count <= 15){
                  this.calculatedPeriod = '15 днів'
                }else{
                  this.calculatedPeriod = count+' днів'
                }
              }

            },*/
            getContractPeriod(){
              if(!this.otkDate && !this.registrationDate){
                this.endOrderDate  = moment(this.startOrderDate, 'DD/MM/YYYY').subtract(1, 'days').add(1, 'years');
                //this.calculatedPeriod = '1 рік';
                this.periodOption = this.periodOptions.find(option => option.id === 12);
              }else{
                this.getPeriodNew();
              }
            },
            hasMixedLayouts(str) {
              const latinPattern = /[a-zA-Z]/;
              const cyrillicPattern = /[а-яА-Я]/;
              const hasLatin = latinPattern.test(str);
              const hasCyrillic = cyrillicPattern.test(str);
              return hasLatin && hasCyrillic;
            },
            async getCarInfo() {
                if (this.car.number && this.car.number.length) {
                    this.numberLoading = true;
                    await axios({url: '/api/directories/car/' + this.car.number.trim(), method: 'GET'}).then(({data}) => {
                        let result = data.data;

                        if(result.number){
                          this.carNumberInfo.status = true;
                        }else{
                          this.carType = null;
                        }
                        this.carNumberInfo.mark = result.mark;
                        this.carNumberInfo.model = result.model;
                        this.carNumberInfo.vin = result.vin;
                        this.carNumberInfo.year = result.year;
                        this.carNumberInfo.autoCategory = result.autoCategory;

                        if(result.city){
                            this.carNumberInfo.city.id = result.city.id;
                            this.carNumberInfo.city.name_ua = result.city.name_ua;
                            this.carNumberInfo.city.name_full_name_ua = result.city.name_full_name_ua;
                            this.carNumberInfo.city.zone = result.city.zone;
                        }else{
                            this.carNumberInfo.city.id = null;
                            this.carNumberInfo.city.name_ua = null;
                            this.carNumberInfo.city.name_full_name_ua = null;
                            this.carNumberInfo.city.zone = null;

                            this.city.id = null;
                            this.city.name = null;
                            this.city.name_full_name_ua = null;
                            this.city.zone = null;
                        }

                        this.numberLoading = false;
                    }).catch(err => (console.log(err)));
                }
            },
            setCarInfo(flagUpdate) {
              this.disableCheckCarData = true;
              if(flagUpdate){
                this.changedCarTypeOptionFlag = this.changedCarTypeFlag = this.changedCityFlag = false;
              }

              return new Promise((resolve) => {
                this.carNumberInfo.status = false;
                if(this.carNumberInfo.city.id && !this.changedCityFlag){
                  this.city.id = this.carNumberInfo.city.id;
                  this.city.name = this.carNumberInfo.city.name_ua;
                  this.city.name_full_name_ua = this.carNumberInfo.city.name_full_name_ua;
                  this.city.zone = this.carNumberInfo.city.zone;
                  this.choseCity();
                }

                if(!this.changedCarTypeFlag){

                  if(!this.carNumberInfo.autoCategory && this.carType.external_id){
                    this.carNumberInfo.autoCategory = this.carType.external_id;
                  }

                  if(this.carNumberInfo.autoCategory == 'B1' || this.carNumberInfo.autoCategory == 'B2' || this.carNumberInfo.autoCategory == 'B3' || this.carNumberInfo.autoCategory == 'B4'){
                    this.carType = this.carTypes[0];
                  }else if(this.carNumberInfo.autoCategory == 'D1' || this.carNumberInfo.autoCategory == 'D2'){
                    this.carType = this.carTypes[1];
                  }else if(this.carNumberInfo.autoCategory == 'C1' || this.carNumberInfo.autoCategory == 'C2'){
                    this.carType = this.carTypes[2];
                  }else if(this.carNumberInfo.autoCategory == 'E' || this.carNumberInfo.autoCategory == 'F'){
                    this.carType = this.carTypes[3];
                  }else if(this.carNumberInfo.autoCategory == 'A1' || this.carNumberInfo.autoCategory == 'A2'){
                    this.carType = this.carTypes[4];
                  }else if(this.carNumberInfo.autoCategory == 'B5'){
                    this.carType = this.carTypes[5];
                  }
                }

                if (this.userType.id == 3 && this.carNumberInfo.autoCategory == 'B4' && !!this.car.number){
                  this.$alert("Пільгове страхування авто з об'ємом понад 2500см3 заборонено. Бажаєте розрахувати без пільги?", '', 'error', {width: '300px'});
                  setTimeout(() => {
                    this.selectUserType(1);
                    this.carTypeOption = {"name":"более 3001 см3","value":"B4"};
                  }, 500);
                }

                if(!this.changedCarTypeOptionFlag){
                  setTimeout(() => {
                    this.carTypeOption = this.carTypeOptions.find((item, index, list) => {
                      return this.carNumberInfo.autoCategory == item.value;
                    });
                    setTimeout(() => { this.disableCheckCarData = false; resolve(); },500);
                  }, 500);
                }else{
                  this.disableCheckCarData = false;
                  resolve();
                }

              });
            },
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


